export enum MycawInitialFormFieldRef {
  Concern1 = 'S2_4_ebp_concern_1',
  Concern1Level = 'S2_6_ebp_concern_1_level',
  Concern2 = 'S2_5_ebp_concern_2',
  Concern2Level = 'S2_7_ebp_concern_2_level',
  FeelingLevel = 'S2_8_ebp_feeling_level',
  PsychologicalImpact = 'S1_1_ebp_phychological_impact',
  PhysicalImpact = 'S1_2_ebp_physical_impact',
  RelationshipsAffected = 'S1_3_ebp_relationships_affected',
  WorkRole = 'S3_9_ebp_work_role',
  WorkPattern = 'S3_10_ebp_work_pattern',
}
