import { InferType, boolean, date, mixed, object, string } from 'yup';
import { OrganizationSchema } from '../OrganizationSchema';
import { CareDocumentRefSchema } from './care-documents';
import {
  CreateEbpPatientSchema,
  EbpPatientSchema,
  UpdateEbpPatientSchema,
} from './EbpPatientSchema';
import { EbpCaseDischargeOptionsSchema } from './EbpCaseDischargeOptionsSchema';
import { IObject } from '../IObject';
import { EbpCaseStatus } from '../enums';

// As it is sent from UI or API requests
export const CreateEbpCaseSchema = object().shape({
  patient: CreateEbpPatientSchema,
  organizationId: string().nullable().notRequired(),
  expertId: string().nullable().notRequired(),
});

export const UpdateEbpCaseSchema = object().shape({
  patient: UpdateEbpPatientSchema,
  expertId: string().nullable().notRequired(),
  consentShareDataToInsurer: boolean(),
  followedUpAfterPcp: boolean(),
});

// As it is stored in the database
export const EbpCaseSchema = UpdateEbpCaseSchema.pick([
  'expertId',
  'consentShareDataToInsurer',
  'followedUpAfterPcp',
]).shape({
  status: mixed<EbpCaseStatus>().oneOf(Object.values(EbpCaseStatus)).required(),
  patient: EbpPatientSchema,
  // calculated field
  organization: OrganizationSchema,
  hasAppointment: boolean(),
  // calculated field
  followedUp: boolean(),
  forms: object().shape({
    mycawInitialFormId: string(),
    mycawFollowUpFormId: string(),
    workFocusedFormId: string(),
  }),
  documents: object().shape({
    pcp: CareDocumentRefSchema,
    workFocusedPlan: CareDocumentRefSchema,
    endOfCareSummary: CareDocumentRefSchema,
    interimCareSummary: CareDocumentRefSchema,
    initialAssessmentSummary: CareDocumentRefSchema,
  }),
  followedUpAfterPcp: boolean(),
  referralId: string(),
  dischargeOptions: EbpCaseDischargeOptionsSchema,
  dischargedAt: date(),
});

export type CreateEbpCase = InferType<typeof CreateEbpCaseSchema>;
export type UpdateEbpCase = InferType<typeof UpdateEbpCaseSchema>;
export type EbpCase = IObject.HasIdTimestamped<InferType<typeof EbpCaseSchema>>;
