export namespace Aws {
  export namespace Textract {
    export declare enum BlockType {
      CELL = 'CELL',
      KEY_VALUE_SET = 'KEY_VALUE_SET',
      LINE = 'LINE',
      PAGE = 'PAGE',
      SELECTION_ELEMENT = 'SELECTION_ELEMENT',
      TABLE = 'TABLE',
      WORD = 'WORD',
    }
    export declare enum EntityType {
      KEY = 'KEY',
      VALUE = 'VALUE',
    }

    export declare enum SelectionStatus {
      NOT_SELECTED = 'NOT_SELECTED',
      SELECTED = 'SELECTED',
    }
    export declare enum TextType {
      HANDWRITING = 'HANDWRITING',
      PRINTED = 'PRINTED',
    }
    export declare enum RelationshipType {
      CHILD = 'CHILD',
      COMPLEX_FEATURES = 'COMPLEX_FEATURES',
      VALUE = 'VALUE',
    }
    /**
     * <p>Information about how blocks are related to each other. A <code>Block</code> object
     *          contains 0 or more <code>Relation</code> objects in a list, <code>Relationships</code>. For
     *          more information, see <a>Block</a>.</p>
     *          <p>The <code>Type</code> element provides the type of the relationship for all blocks in
     *          the <code>IDs</code> array. </p>
     */
    export interface Relationship {
      /**
       * <p>The type of relationship that the blocks in the IDs array have with the current block.
       *          The relationship can be <code>VALUE</code> or <code>CHILD</code>. A relationship of type
       *          VALUE is a list that contains the ID of the VALUE block that's associated with the KEY of a key-value pair.
       *          A relationship of type CHILD is a list of IDs that identify WORD blocks in the case of lines
       *          Cell blocks in the case of Tables, and WORD blocks in the case of Selection Elements.</p>
       */
      Type?: RelationshipType | string;
      /**
       * <p>An
       *          array of IDs for related blocks. You can get the type of the relationship from the
       *             <code>Type</code> element.</p>
       */
      Ids?: string[];
    }

    export interface BoundingBox {
      /**
       * <p>The width of the bounding box as a ratio of the overall document page
       *          width.</p>
       */
      Width?: number;
      /**
       * <p>The height of the bounding box as a ratio of the overall document page
       *          height.</p>
       */
      Height?: number;
      /**
       * <p>The left coordinate of the bounding box as a ratio of overall document page
       *          width.</p>
       */
      Left?: number;
      /**
       * <p>The top coordinate of the bounding box as a ratio of overall document page
       *          height.</p>
       */
      Top?: number;
    }

    export interface Geometry {
      /**
       * <p>An axis-aligned coarse representation of the location of the recognized item on the
       *          document page.</p>
       */
      BoundingBox?: BoundingBox;
      /**
       * <p>Within the bounding box, a fine-grained polygon around the recognized item.</p>
       */
      Polygon?: Point[];
    }

    export interface Point {
      /**
       * <p>The value of the X coordinate for a point on a <code>Polygon</code>.</p>
       */
      X?: number;
      /**
       * <p>The value of the Y coordinate for a point on a <code>Polygon</code>.</p>
       */
      Y?: number;
    }

    export interface Block {
      BlockType?: BlockType | string;
      /**
       * <p>The confidence score that Amazon Textract has in the accuracy of the recognized text and
       *          the accuracy of the geometry points around the recognized text.</p>
       */
      Confidence?: number;
      /**
       * <p>The word or line of text that's recognized by Amazon Textract. </p>
       */
      Text?: string;
      /**
       * <p>The kind of text that Amazon Textract has detected. Can check for handwritten text and printed text.</p>
       */
      TextType?: TextType | string;
      /**
       * <p>The row in which a table cell is located. The first row position is 1.
       *             <code>RowIndex</code> isn't returned by <code>DetectDocumentText</code> and
       *             <code>GetDocumentTextDetection</code>.</p>
       */
      RowIndex?: number;
      /**
       * <p>The column in which a table cell appears. The first column position is 1.
       *             <code>ColumnIndex</code> isn't returned by <code>DetectDocumentText</code> and
       *             <code>GetDocumentTextDetection</code>.</p>
       */
      ColumnIndex?: number;
      /**
       * <p>The number of rows that a table cell spans. Currently this value is always 1, even
       *          if the number of rows spanned is greater than 1. <code>RowSpan</code> isn't returned by
       *             <code>DetectDocumentText</code> and <code>GetDocumentTextDetection</code>.</p>
       */
      RowSpan?: number;
      /**
       * <p>The number of columns that a table cell spans. Currently this value is always 1, even
       *          if the number of columns spanned is greater than 1. <code>ColumnSpan</code> isn't returned by
       *             <code>DetectDocumentText</code> and <code>GetDocumentTextDetection</code>. </p>
       */
      ColumnSpan?: number;
      /**
       * <p>The location of the recognized text on the image. It includes an axis-aligned, coarse
       *          bounding box that surrounds the text, and a finer-grain polygon for more accurate spatial
       *          information. </p>
       */
      Geometry?: Geometry;
      /**
       * <p>The identifier for the recognized text. The identifier is only unique for a single
       *          operation. </p>
       */
      Id?: string;
      /**
       * <p>A list of child blocks of the current block. For example, a LINE object has child blocks
       *          for each WORD block that's part of the line of text. There aren't Relationship objects in
       *          the list for relationships that don't exist, such as when the current block has no child
       *          blocks. The list size can be the following:</p>
       *          <ul>
       *             <li>
       *                <p>0 - The block has no child blocks.</p>
       *             </li>
       *             <li>
       *                <p>1 - The block has child blocks.</p>
       *             </li>
       *          </ul>
       */
      Relationships?: Relationship[];
      /**
       * <p>The type of entity. The following can be returned:</p>
       *          <ul>
       *             <li>
       *                <p>
       *                   <i>KEY</i> - An identifier for a field on the document.</p>
       *             </li>
       *             <li>
       *                <p>
       *                   <i>VALUE</i> - The field text.</p>
       *             </li>
       *          </ul>
       *          <p>
       *             <code>EntityTypes</code> isn't returned by <code>DetectDocumentText</code> and
       *             <code>GetDocumentTextDetection</code>.</p>
       */
      EntityTypes?: (EntityType | string)[];
      /**
       * <p>The selection status of a selection element, such as an option button or check box. </p>
       */
      SelectionStatus?: SelectionStatus | string;
      /**
       * <p>The page on which a block was detected. <code>Page</code> is returned by asynchronous
       *          operations. Page values greater than 1 are only returned for multipage documents that are
       *          in PDF format. A scanned image (JPEG/PNG), even if it contains multiple document pages, is
       *          considered to be a single-page document. The value of <code>Page</code> is always 1.
       *          Synchronous operations don't return <code>Page</code> because every input document is
       *          considered to be a single-page document.</p>
       */
      Page?: number;
    }
  }
}
