import { MycawInitialForm, MycawInitialFormFieldRef, TypeformField } from '..';

export class MycawInitialFormHelper {
  constructor(private readonly form?: MycawInitialForm | null) {}

  getFormValue = (ref?: string) => {
    if (!this.form) {
      return null;
    }

    const field = Object.values(this.form).find(
      (field) => (field as TypeformField | undefined)?.ref === ref,
    ) as TypeformField | undefined;

    return field?.value ?? null;
  };

  getInitialConcern1() {
    return {
      concern: this.getFormValue(MycawInitialFormFieldRef.Concern1),
      level: this.getFormValue(MycawInitialFormFieldRef.Concern1Level),
    };
  }
  getInitialConcern2() {
    return {
      concern: this.getFormValue(MycawInitialFormFieldRef.Concern2),
      level: this.getFormValue(MycawInitialFormFieldRef.Concern2Level),
    };
  }

  getFeelingLevel() {
    return this.getFormValue(MycawInitialFormFieldRef.FeelingLevel);
  }

  getPsychologicalImpacts(): string[] {
    return this.stringToArray(
      this.getFormValue(MycawInitialFormFieldRef.PsychologicalImpact),
    );
  }

  getPhysicalImpacts(): string[] {
    return this.stringToArray(
      this.getFormValue(MycawInitialFormFieldRef.PhysicalImpact),
    );
  }

  getAffectedRelationships(): string[] {
    return this.stringToArray(
      this.getFormValue(MycawInitialFormFieldRef.RelationshipsAffected),
    );
  }

  getWorkRole() {
    return this.getFormValue(MycawInitialFormFieldRef.WorkRole);
  }

  getWorkPattern() {
    return this.getFormValue(MycawInitialFormFieldRef.WorkPattern);
  }

  private stringToArray(str: string | null) {
    if (!str) {
      return [];
    }

    return (
      str
        .split(',')
        // Trim whitespace
        .map((s) => s.replace(/^\s\s*/, '').replace(/\s\s*$/, ''))
    );
  }

  buildFollowUpFormLink = ({
    followUpFormId,
    email,
    patientFullName,
  }: {
    followUpFormId: string;
    email?: string | null;
    patientFullName?: string | null;
  }) => {
    const concern1 = this.getInitialConcern1();
    const concern2 = this.getInitialConcern2();

    if (!email) {
      return null;
    }

    let link = `https://percihealth.typeform.com/to/${followUpFormId}#email=${encodeURIComponent(
      email,
    )}`;
    if (patientFullName) {
      link += `&name=${encodeURIComponent(patientFullName)}`;
    }

    if (concern1.concern) {
      link += `&concern1=${encodeURIComponent(concern1.concern)}`;
    }
    if (concern2.concern) {
      link += `&concern2=${encodeURIComponent(concern2.concern)}`;
    }

    return link;
  };
}
