import { CareDocumentType } from '..';

export const getEbpDocumentFileRef = ({
  documentId,
  type,
  caseId,
}: {
  documentId: string;
  type: CareDocumentType;
  caseId: string;
}) => {
  // Initially only PCP documents were supported and located in the "pcp" folder.
  // See getOldPcpFileRef.ts for more details.
  // Later we added the "documents" folder and started supporting other document types.
  return `cases/${caseId}/${type}/${documentId}.pdf`;
};
