import {
  MycawFollowUpFieldRef,
  MycawFollowUpFormData,
  TypeformField,
} from '..';

export class MycawFollowUpFormHelper {
  constructor(private readonly form?: MycawFollowUpFormData | null) {}

  getFormValue = (ref?: string) => {
    if (!this.form) {
      return null;
    }

    const field = Object.values(this.form).find(
      (field) => (field as TypeformField | undefined)?.ref === ref,
    ) as TypeformField | undefined;

    return field?.value ?? null;
  };

  getConcern1Level() {
    return this.getFormValue(MycawFollowUpFieldRef.Concern1Level);
  }
  getConcern2Level() {
    return this.getFormValue(MycawFollowUpFieldRef.Concern2Level);
  }

  getFeelingLevel() {
    return this.getFormValue(MycawFollowUpFieldRef.FeelingLevel);
  }

  getOtherFactors() {
    return this.getFormValue(MycawFollowUpFieldRef.OtherAspect);
  }

  getMostImportantSupportAspect() {
    return this.getFormValue(MycawFollowUpFieldRef.MostImportantSupportAspect);
  }
}
