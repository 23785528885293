import { InferType, array, mixed, object, string } from 'yup';
import { RecommendedSupportStatus } from '../../../enums';

export const PcpPatientServiceSchema = object().shape({
  service: object()
    .shape({
      id: string().required(),
      name: string().required(),
      healee_ref: string().required(),
    })
    .required(),

  concerns: array().of(string().required()),

  appointmentId: string(),

  professional: object()
    .shape({
      // Healee returns only name for a doctor in appointment updates,
      // so id is optional
      id: string().optional(),
      name: string().required('Professional name is required'),
    })
    .optional()
    .default(undefined),

  status: mixed<RecommendedSupportStatus>()
    .oneOf(Object.values(RecommendedSupportStatus))
    .required(),
});

export type PcpPatientService = InferType<typeof PcpPatientServiceSchema>;
