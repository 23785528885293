import {
  TypeformField,
  WorkFocusFormData,
  WorkFocusedPlanFormFieldRef,
} from '../schemas';

export class WorkFocusedPlanFormHelper {
  constructor(private readonly form?: WorkFocusFormData | null) {}

  getFormValue = (ref?: string) => {
    if (!this.form) {
      return undefined;
    }

    const field = Object.values(this.form).find(
      (field) => (field as TypeformField | undefined)?.ref === ref,
    ) as TypeformField | undefined;

    return field?.value;
  };

  getFatigue() {
    return this.getFormValue(WorkFocusedPlanFormFieldRef.Fatigue);
  }

  getRtwSe() {
    return this.form?.var_rtw_se;
  }
}
