export const getPcpFileName = ({
  patientFullname,
  pcpDateTime,
}: {
  patientFullname?: string;
  pcpDateTime?: Date;
}): string => {
  const dateParts = pcpDateTime?.toISOString().split('T')[0].split('-') ?? [];
  const date = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
  const time = pcpDateTime
    ?.toISOString()
    .split('T')[1]
    .substring(0, 8)
    .replace(/:/g, '-');
  return `PCP - ${patientFullname ?? ''} - ${date} ${time}`;
};
